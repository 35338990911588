import S1 from  './weavelifec1.jpg'
import BasicExample from './signup'
import s1 from './M1.jpg'
import s2 from './reactscreen2.png'
import s3 from './reactscreen3.png'
import m1notes from '../src/M1reedited_removed (1).pdf'
//import AndroidNotes from '../src/Android_CompleteNotes.pdf'


import Image from './ImageSlide'
const East = ()=>{
 return(

     <div style={{backgroundColor:"white"}}>
        <Image image1={s1} image2={s2} image3={s3} />
        <h1 style={{color:"black",textAlign:"center", textShadow:"1px 1px 20px yellow"}}>preview</h1>

        <BasicExample name={m1notes} value="Maths made by sailu madam" type="Download" image={S1}/>   



     </div>
    


 )



    }
    export default East;